import React from 'react'

class Animation extends React.Component {
  constructor(props) {
    super(props)

    this.animation = 0
  }

  componentDidMount() {
    const script = document.createElement("script")

    script.src = "./lottie.js"
    script.async = true

    script.onload = () => {
      this.animation = window.lottie.loadAnimation({
        container: document.getElementById(this.props.number + 'lottie'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: this.props.number === 1 ? './data-step1.json' : './data-step2.json'
      })

      this.animation.addEventListener('DOMLoaded', () => {
        this.props.isReady(this.props.number)
      })
    }

    document.body.appendChild(script)
  }

  componentDidUpdate() {
    if (!this.props.isPaused) {
      this.animation.play()
    }
  }

  render() {
    return <div id={this.props.number + 'lottie'}></div>
  }
}

export default Animation
