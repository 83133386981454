import React from 'react'
import { request } from 'graphql-request'
import { Form, Icon, Input, Button, message } from 'antd';
import { Background, Container, Row, Col } from '../home/styles'
import styled from 'styled-components'
import { Consumer } from '../../utils/mixpanel'
import ReactPixel from 'react-facebook-pixel'

const Heading = styled.h2`
  margin-top: 0;
  line-height: 1.5em;
`

const FormItem = Form.Item;

class ResetForm extends React.Component {
  componentDidMount () {
      // const pageclipScript = document.createElement("script");

      // pageclipScript.src = "https://s.pageclip.co/v1/pageclip.js";
      // pageclipScript.async = true;

      // document.body.appendChild(pageclipScript);

      // const link = document.createElement( "link" );
      // link.href = 'https://s.pageclip.co/v1/pageclip.css'
      // link.rel = "stylesheet";
      // link.media = "screen";

      // document.getElementsByTagName( "head" )[0].appendChild( link );
  }

  handleSubmit = (e, {mixpanel}) => {
    e.preventDefault();
    console.log('SUBMIT')

    
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const hide = message.loading('Generating ebook …', 0);
        console.log('Received values of form: ', values)

        const mut = `
          mutation sendForm($email: String!, $name: String!) {
            sendForm(email: $email, name: $name){
              result
            }
          }
        `

        const variables = {
          name: values.name,
          email: values.email,
        }

        request('https://api.graph.cool/simple/v1/cj2swqchy0pmh01230nac6864', mut, variables).then(data => {
          console.log({data})
          
          if (!data.sendForm.result) {
            message.error('Error  😐')
          }
        })

        setTimeout(() => {
          hide()
          message.success('Success! Check your email 🎁')
            this.props.form.resetFields(['name', 'email']);
            mixpanel.track('Requested Book')
            ReactPixel.track('Lead')
            window.qp('track', 'GenerateLead')
        }, 5000)
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Consumer>
        {mixpanel => (
          <Background>
            <Container>
              <form method="POST" action="https://send.pageclip.co/6FdyDibBv2qjBg0om9gZgmioir7cEIBL"
              onSubmit={e => this.handleSubmit(e, {mixpanel})}
              className="login-form pageclip-form" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
              >
                <Row>
                  <Col min={20}>
                    <Heading>{this.props.additionalText} Request access.</Heading>
                    <p>I’ll send you a book with your name in it.</p>
                    <FormItem style={{marginBottom: '1em'}}>
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'I need an email' }],
                      })(
                        <Input name="email" prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />} type="email" placeholder="Email" />
                      )}
                    </FormItem>
                    <FormItem style={{marginBottom: '1em'}}>
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'What’s your name?' }],
                      })(
                        <Input name="name" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} type="name" placeholder="Name" />
                      )}
                    </FormItem>
                    {/* <Button href={thisOSDownload} onClick={() => {
                      mixpanel.track('Download', {position: `StartTrial: ${this.props.position}`})
                      ReactPixel.track('Lead')
                      window.qp('track', 'GenerateLead')
                    }} type="primary" icon="download">Try it for free</Button> */}
                    <FormItem>
                      <Button type="primary" htmlType="submit" className="login-form-button pageclip-form__submit">
                        Sign me up
                      </Button>
                    </FormItem>
                  </Col>
                </Row>
              </form>
            </Container>
          </Background>
        )}
      </Consumer>
    );
  }
}

const WrappedResetForm = Form.create()(ResetForm);

export default WrappedResetForm
