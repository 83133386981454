import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Background } from './styles'
import TrialText from './TrialText'
import { Button } from 'antd'
import { Consumer } from '../../utils/mixpanel'
import ReactPixel from 'react-facebook-pixel'
import { version } from '../../../../electron/app/package.json'

const Heading = styled.h2`
  margin-top: 0;
  line-height: 1.5em;
`

export default class Pain extends React.Component {
  getData() {
    const isWindows = typeof navigator !== 'undefined' && navigator.appVersion.indexOf("Windows") !== -1
    const thisOSName = !isWindows ? 'macOS' : 'Windows'
    const otherOSName = isWindows ? 'macOS' : 'Windows'
    const iconName = !isWindows ? 'apple' : 'windows'
    const macDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions-${version}.dmg`
    const windowsDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions+Setup+${version}.exe`
    const thisOSDownload = !isWindows ? macDownload : windowsDownload
    const otherOSDownload = isWindows ? macDownload : windowsDownload

    return {
      iconName,
      thisOSName,
      otherOSName,
      thisOSDownload,
      otherOSDownload
    }
  }

  constructor(props) {
    super(props)
    const data = this.getData()
    this.state = data
  }

  setData() {
    const data = this.getData()
    this.setState(data)
  }

  componentDidMount() {
    this.setData()
    this.forceUpdate()
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    return (
      <Consumer>
        {mixpanel => (
          <Background>
            <Container padding={4}>
              <Row>
                <Col min={20}>
                  <Heading>{this.props.text}</Heading>
                  <p>Try the desktop app for free, for 7 days. Find better questions today. No commitments.</p>
                  <TrialText/>
                </Col>
                <Col grow={0}>
                  <Button 
                  disabled
                  // href={this.state.thisOSDownload} onClick={() => {
                  //   mixpanel.track('Download', {position: `StartTrial: ${this.props.position}`})
                  //   ReactPixel.track('Lead')
                  //   window.qp('track', 'GenerateLead')
                  // }} 
                  type="primary" icon="download">Try it for free</Button>
                </Col>
              </Row>
            </Container>
          </Background>
        )}
      </Consumer>
    )
  }
}
