import styled from 'styled-components'

const Container = styled.div`
  margin: 3rem auto;
  max-width: 96%;
  width: 42rem;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding: ${props => props.padding}em 0;
`

export default Container
