import React from 'react'
import styled from 'styled-components'

const Box = styled.div`
  text-transform: uppercase;
  color: grey;
`

export default () => (
  <Box>Free 7 day trial | No credit card required</Box>
)
