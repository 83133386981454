import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { Consumer } from '../utils/mixpanel'
import Logo from '../components/home/Logo'
import ReactPixel from 'react-facebook-pixel'
import { version } from '../utils/download'
import 'typeface-lora'
import 'typeface-karla'

const Menu = styled.ul`
  margin: 0;
  height: 2rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;

  li {
    margin-bottom: 0;

    &:last-child {
      a {
        box-shadow: 0 1px 0 0 currentColor;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }

  a {
    box-shadow: none;
  }

`

const ListItem = styled.li`
  margin-right: 2em;
`

const LogoListItem = styled.li`
  margin-right: auto;
  margin-left: 1.1rem;

  a {
    height: 2rem;
    margin-right: 1rem;
    display: block;
  }

  svg {
    max-height: 100%;
  }
`

class Layout extends React.Component {
  componentDidMount() {
    // Facebook
    ReactPixel.init('312369006179541')
    ReactPixel.track('PageView')

    // Quora
    // eslint-disable-next-line no-unused-expressions
    !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
    window.qp('init', '996d02860ebf485e89d42e5999a28c5a');
    window.qp('track', 'ViewContent');

    // Autopilot
    (function(o){var b="https://api.autopilothq.com/anywhere/",t="a37742441d9e4925a54d55748ee7b3d7faeff3131410468ca76858f31588c063",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});
  
    this.setData()
    this.forceUpdate()
  }

  getData() {
    const isWindows = typeof navigator !== 'undefined' && navigator.appVersion.indexOf("Windows") !== -1
    const thisOSName = !isWindows ? 'macOS' : 'Windows'
    const otherOSName = isWindows ? 'macOS' : 'Windows'
    const iconName = !isWindows ? 'apple' : 'windows'
    const macDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions-${version}.dmg`
    const windowsDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions+Setup+${version}.exe`
    const thisOSDownload = !isWindows ? macDownload : windowsDownload
    const otherOSDownload = isWindows ? macDownload : windowsDownload

    return {
      iconName,
      thisOSName,
      otherOSName,
      thisOSDownload,
      otherOSDownload
    }
  }

  constructor(props) {
    super(props)
    const data = this.getData()
    this.state = data
  }

  setData() {
    const data = this.getData()
    this.setState(data)
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    return (
      <Consumer>
        {mixpanel => (
          <div>
            <Menu>
              <LogoListItem><Link to="/">
              <Logo color={'#2E4057'} size={'2rem'}/>
              </Link></LogoListItem>
              <ListItem><Link to="/blog/">Blog</Link></ListItem>
              <ListItem><Link to="/" onClick={event => {
                setTimeout(() => {
                  const pricingNode = document.getElementById('pricing')
                  window.scroll(0, pricingNode.offsetTop - 30)
                }, 50)
              }}>Pricing</Link></ListItem>
              {/* <ListItem><a href={this.state.thisOSDownload} onClick={() => {
                mixpanel.track('Download', {position: `Menu`})
                ReactPixel.track('Lead')
                window.qp('track', 'GenerateLead')
              }}>Get started: Download</a></ListItem> */}
            </Menu>

            {this.props.children}
          </div>
        )}
      </Consumer>
    )
  }
}

export default Layout
