import styled from 'styled-components'

const Background = styled.section`
  background: #FFF8E8;
  margin-bottom: 7em;
  padding: 1rem 0;
`

const Container = styled.div`
  margin: 5rem auto;
  max-width: 92%;
  width: 42rem;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding: ${props => props.padding}em 0;
`

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${props => props.alignItems || 'center'};
`

const Col = styled.div`
  flex-grow: ${props => props.grow !== undefined ? props.grow : 1};
  flex-basis: 0;
  max-width: 100%;
  min-width: ${props => props.min}rem;
  padding: 0 8px;
`

const SectionTitles = styled.hgroup`
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 2.5em;
    margin-top: 0em;
  }

  h4 {
    color: #1c2296;
    margin-top: 0;
  }
`

const TitleDescription = styled.p`
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
`

export { Background, Container, SectionTitles, Row, Col, TitleDescription }
