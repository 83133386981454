import React from 'react'
import styled from 'styled-components'
import { Button, Icon } from 'antd'
import { version } from '../../../utils/download'
import { Consumer } from '../../../utils/mixpanel'
import ReactPixel from 'react-facebook-pixel'

const DownloadForText = styled.span`
  font-size: 14px;
`

const AlternativeDownload = styled.a`
  margin-left: 20px;
`

class DownloadSection extends React.Component {
  getData() {
    const isWindows = typeof navigator !== 'undefined' && navigator.appVersion.indexOf("Windows") !== -1
    const thisOSName = !isWindows ? 'macOS' : 'Windows'
    const otherOSName = isWindows ? 'macOS' : 'Windows'
    const iconName = !isWindows ? 'apple' : 'windows'
    const macDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions-${version}.dmg`
    const windowsDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions+Setup+${version}.exe`
    const thisOSDownload = !isWindows ? macDownload : windowsDownload
    const otherOSDownload = isWindows ? macDownload : windowsDownload

    return {
      iconName,
      thisOSName,
      otherOSName,
      thisOSDownload,
      otherOSDownload
    }
  }

  constructor(props) {
    super(props)
    const data = this.getData()
    this.state = data
  }

  setData() {
    const data = this.getData()
    this.setState(data)
  }

  componentDidMount() {
    this.setData()
    this.forceUpdate()
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    if (typeof window === 'undefined') return <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      marginBottom: '2rem'
    }}>Download available for macOS and Windows</div>
    const {
      iconName,
      thisOSName,
      otherOSName,
      thisOSDownload,
      otherOSDownload
    } = this.state


    return (
      <Consumer>
        {mixpanel => (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginBottom: '2rem'
          }}>
            <Button
              disabled
              // href={thisOSDownload}
              // onClick={() => {
              //   mixpanel.track('Download', {position: 'Header'})
              //   ReactPixel.track('Lead')
              //   window.qp('track', 'GenerateLead')
              // }}
              type="primary"
              size="large"
              style={{
                fontSize: '18px',
                lineHeight: '23px',
                height: '65px',
                display: 'flex',
                alignItems: 'center'

                // background-color: #1c2296;
                // border-color: #1c2296;
                // color: #fff;
                // border: 1px solid #d9d9d9;
                // border-radius: 4px;
              }}
            >
              <Icon type={iconName} style={{fontSize: '30px', paddingRight: '7px'}}/>
              <div style={{
                textAlign: 'left',
                marginTop: '-6px'
              }}>
                <DownloadForText>Download for<br/></DownloadForText>{thisOSName}
              </div>
            </Button>
            <AlternativeDownload 
              // href={otherOSDownload}
              // onClick={() => {
              //   mixpanel.track('Download', {position: 'Header'})
              //   ReactPixel.track('Lead')
              //   window.qp('track', 'GenerateLead')
              // }}
            >
              or for {otherOSName}
            </AlternativeDownload>
          </div>
        )}
      </Consumer>
    )
  }
}

export default DownloadSection
