import React from 'react'
import styled from 'styled-components'
import pngFile from './pain/windows.png'
import { SectionTitles, Container, Row, Col, TitleDescription } from './styles'

const Image = styled.img`
  max-height: 14rem;
  margin: 0 auto;
  display: block;
`

const Section = styled(Row)`
  margin-top: 4rem;

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`

const Quote = styled.blockquote`
  border-width: 0;
  font-style: normal;
  color: initial;
  
  h2 {
    margin-top: 0;
    line-height: 1.45em;
    flex-shrink: 1;
    text-indent: -0.4em;
    margin-top: -.3em;
  }

  p {
    margin-bottom: 0;
  }

  img {
    margin-right: 1em;
  }

  @media only screen and (max-width: 600px) {
    ${Row} {
      flex-direction: column !important;
      text-align: center;
    }

    img {
      margin: -2em auto 1.5em;
    }
  }
`

const ProfileImg = styled.img`
  border-radius: 100%;
  height: 100px;
`

export default class Pain extends React.Component {
  render() {
    return (
      <Container>
        <Quote>
          <Row alignItems="flex-start">
            <ProfileImg src="./assets/si-quan-ong-profile-picture.jpeg" alt="Si Quan Ong"/>
            <Col>
              <h2>“One of the biggest problems in writing for Quora is finding good questions to answer.”</h2>
              <p>– Si Quan Ong, <a target="_blank" href="https://www.quora.com/profile/Si-Quan-Ong-2">ahrefs</a></p>
            </Col>
          </Row>
        </Quote>
        <TitleDescription>You want to make sure to get optimal results from Quora.  But still, you shouldn't waste hours searching for the question with the best stats. That’s where Find Better Questions comes in.</TitleDescription>
        <Section>
          <Col min={20}>
            <h3>With 15M+ questions, you’ll need some help prioritizing them</h3>
            <p>Quora wasn’t designed for marketers. Finding the questions that will generate traffic is a ton of work! What if we do it for you?</p>
          </Col>
          <Col min={12}>
            <Image src={pngFile} alt="Quora Research Workflow"/>
          </Col>
        </Section>
      </Container>
    )
  }
}
