import React from 'react'
import styled from 'styled-components'
import { SectionTitles, Container } from './styles'

const SubHeading = styled.h3`
  margin-bottom: -2rem;
`

const faqs = [
  {
    type: 'heading',
    heading: 'The Process'
  },
  {
    id: 'why-desktop',
    q: 'Why is this a desktop app? Why do I need to download it?',
    a: 'For technical reasons. Quora only allows you to see a question’s stats when you’re logged in. Since we can’t log in for you, you have to do it with our app.'
  },
  {
    q: 'What are inputs?',
    a: 'An input is a reference point from which a list of questions is created. It can be the url of a profile, topic, question or answer.'
  },
  {
    q: 'What does list length mean?',
    a: 'The number of questions in each list created. The Pro plan will create lists with 30 rows, the Guru plan will create lists with 70 rows / questions you can choose from.'
  },
  {
    q: 'What are Alarms & Updated Lists?',
    a: 'Questions don’t stay the same. Quora is always changing, so you want to know when a question in your industry reaches a new high. You can configure alarms, which will cause your lists to update periodically. You can also configure on which changes you want to be notified. (Later: read more in this guide)'
  },
  {
    q: 'Will Find Better Questions work for me?',
    a: 'If you are planning the put some real effort into Quora Marketing by providing real value, and not hacking the system, Find Better Questions can help you spend your time more wisely.'
  },
  {
    type: 'heading',
    heading: '$'
  },
  // {
  //   q: 'Is there a discount?',
  //   a: 'Yes, the launch party is happening. Get 50% off Find Better Questions today.'
  // },
  {
    q: 'How is the Pro plan different from the Guru plan?',
    a: 'The largest difference is how many lists you can create. The lists of the Guru plan also have more entries / questions in them, so there’s a higher chance of finding a nugget.'
  },
  {
    q: 'Can I try Find Better Questions before I buy?',
    a: 'The trial is 7 days. You don’t even need a credit card. No commitments made.'
  },
  {
    q: 'Are there any overage fees?',
    a: 'No. Once you’ve reached your limit, you can’t create additional lists.'
  },
  {
    q: 'Can I change my plan?',
    a: 'Yes, you can change your plan at any time after the trial period.'
  },
  {
    q: 'What happens if I change plans?',
    a: 'Your account will be charged or credited based on the percentage of the billing cycle left at the time the plan was changed. Changes are effective immediately.'
  },
  {
    q: 'What happens if I cancel my paid plans?',
    a: 'When you cancel your plan, you will remain on the plan until the end of your billing cycle, at which time the plan will be canceled.'
  },
  {
    q: 'How is the payment being processed?',
    a: 'We use Chargebee to manage our subscription billing. Payments are processed by Stripe. It’s the same payment provider used in products such as Twitter, Pinterest, and Lyft. We do not handle your credit card information directly.'
  },
  {
    type: 'space'
  },
  {
    q: 'Is support included?',
    a: 'You can always reach out via chat or email. I’ll be glad to answer any questions, but the support is limited to the product. It doesn’t include writing your answers or helping in any other way than the product promotes. I’m always happy to talk though. 😊'
  }
]

export { faqs }

export default class FAQ extends React.Component {
  render() {
    return (
      <Container>
        <SectionTitles>
          <h2>Frequent Questions</h2>
        </SectionTitles>
        {faqs.map(set => {
          switch(set.type) {
            case('space'): return (<p key={set.type}></p>)
            case('heading'): return (<SubHeading key={set.heading}>{set.heading}</SubHeading>)
            default: return (
              <div key={set.q} id={set.id}>
                <h4>{set.q}</h4>
                <p>{set.a}</p>
              </div>
            )
          }
        })}
        <div>
          <h3>Still have questions?</h3>
          Contact me at contact@findbetterquestions.com
        </div>
      </Container>
    )
  }
}
