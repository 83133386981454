import React from 'react'
import { Link } from 'gatsby';
import DownloadSection from './header/DownloadSection'
import { Container } from './styles'
import TrialText from './TrialText'
import styled from 'styled-components'

const Heading = styled.h1`
  color: #2E4057;
`

const SubHeading = styled.h3`
  font-weight: normal;
  color: grey;
  line-height: 1.4em;
  text-transform: none;
  font-size: 1.25rem;
`

const HeaderContainer = styled(Container)`
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  a {
    box-shadow: none;
  }
`

const QuoraText = styled.span`
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  color: grey;
  font-size: .7em;
`

export default class Header extends React.Component {
  render() {
    return (
      <HeaderContainer>
        <hgroup>
          <Heading>Find Better Questions <br/><QuoraText>on Quora</QuoraText></Heading>
          <SubHeading>Find Better Questions is no longer available</SubHeading>
          <SubHeading>Find the best questions to answer <br/>Get the most traffic and leads</SubHeading>
        </hgroup>
        <DownloadSection/>
        <TrialText/>
        <Link to="/" onClick={event => {
          setTimeout(() => {
            const node = document.getElementById('demo')
            window.scroll(0, node.offsetTop - 30)
          }, 50)
        }}>
          WATCH DEMO VIDEO
        </Link>
      </HeaderContainer>
    )
  }
}
