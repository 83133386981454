import React from 'react'
import styled from 'styled-components'
import { Tooltip, Icon, Button } from 'antd'
import { SectionTitles, Container } from './styles'
import TrialText from './TrialText'
import { Consumer } from '../../utils/mixpanel'
import ReactPixel from 'react-facebook-pixel'
import { version } from '../../../../electron/app/package.json'

const NotAvailable = styled.span`
  text-decoration: line-through;
  color: grey;
`

const Title = styled.h2`
  margin-bottom: 0.3em;
`

const Subtitle = styled.h3`
  margin-top: 0;
`

const List = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  list-style: none;

  i {
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 2em;
  }

  i::before {
    color: #52c41a;
    margin-right: 1rem;
    border: #52c41a solid 0.1rem;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 2rem;
    position: absolute;
    top: -0.4rem;
    margin-left: -2.2em;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 50%;
    transform: translateY(-50%);
  }
`

const Plans = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7em;
  margin-top: 2em;

  @media (max-width: 45rem) {
    justify-content: center;
  }
`

const Plan = styled.div`
  text-align: center;

  margin: 1em 0;
  padding-bottom: 3.1em;
  flex-basis: 0;

  @media (max-width: 45rem) {
    flex-basis: initial;
  }
  min-width: 20rem;

  &:first-child {
    padding: 2px;
  }

  &:last-child {
    border-radius: 4px;
    border: solid 2px #1C2296;
  }

  ul {
    margin-top: 2rem;
    list-style: none;
  }

  h3 {
    margin-top: 3rem;
    font-size: 2rem;
    margin-bottom: 0;
  }

  h4 {
    margin-top: 1.5em;
    color: grey;
  }
`

const Price = styled.div`
  font-size: 1.5em;
`

const PriceDetails = styled.span`
  font-size: .8em;
  display: block;
`

const StyledButton = styled(Button)`
  box-shadow: none;
  margin-bottom: 3em;
`

const FreeTrialBox = styled.div`
  font-size: .8em;
`

const alarmsHintText = 'Stay updated on the questions in your industry'

const plans = [
  {
    name: 'Pro',
    isPrimary: false,
    tagline: 'Answer better questions',
    monthlyPrice: 24,
    annualPrice: 19,
    features: [
      {
        text: '30 Lists / month',
        striked: false
      },
      {
        text: 'Limited List Size',
        striked: false,
        hintText: 'Lists will have 30 questions'
      },
      {
        text: 'Get weekly views',
        striked: false,
        hintText: 'Know how many views questions get per week'
      },
      // {
      //   text: 'Alarms and Updated Lists',
      //   striked: true,
      //   hintText: alarmsHintText
      // },
      {
        text: 'CSV Export',
        striked: true
      },
    ]
  },
  {
    name: 'Guru',
    isPrimary: true,
    tagline: 'Get the most out of Quora',
    monthlyPrice: 59,
    annualPrice: 49,
    features: [
      {
        text: 'Unlimited Lists',
        striked: false
      },
      {
        text: 'Extended List Size',
        striked: false,
        hintText: 'Lists will have 70 questions'
      },
      {
        text: 'Get weekly views',
        striked: false,
        hintText: 'Know how many views questions get per week'
      },
      // {
      //   text: 'Alarms and Updated Lists',
      //   striked: false,
      //   hintText: alarmsHintText
      // },
      {
        text: 'CSV Export',
        striked: false
      },
    ]
  }
]

export default class Pricing extends React.Component {
  getData() {
    const isWindows = typeof navigator !== 'undefined' && navigator.appVersion.indexOf("Windows") !== -1
    const thisOSName = !isWindows ? 'macOS' : 'Windows'
    const otherOSName = isWindows ? 'macOS' : 'Windows'
    const iconName = !isWindows ? 'apple' : 'windows'
    const macDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions-${version}.dmg`
    const windowsDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions+Setup+${version}.exe`
    const thisOSDownload = !isWindows ? macDownload : windowsDownload
    const otherOSDownload = isWindows ? macDownload : windowsDownload

    return {
      iconName,
      thisOSName,
      otherOSName,
      thisOSDownload,
      otherOSDownload
    }
  }

  constructor(props) {
    super(props)
    const data = this.getData()
    this.state = data
  }

  setData() {
    const data = this.getData()
    this.setState(data)
  }

  componentDidMount() {
    this.setData()
    this.forceUpdate()
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    return (
      <Consumer>
        {mixpanel => (
          <Container id="pricing">
            <SectionTitles>
              <h4>Pricing Plans</h4>
              <Title>Get More Traffic </Title>
              <Subtitle>for less than $2 a day</Subtitle>
            </SectionTitles>

            <List>
              <i className="anticon anticon-check">More Traffic</i>
              <i className="anticon anticon-check">Save 90% Time</i>
              <i className="anticon anticon-check">No Headaches</i>
            </List>

            <Plans>
              {plans.map(plan => (
                <Plan key={plan.name}>
                  <hgroup>
                    <h3>{plan.name}</h3>
                    <h4>{plan.tagline}</h4>
                  </hgroup>
                  <div>
                    <Price>
                      ${plan.annualPrice}
                    </Price>
                    <PriceDetails>
                      /month, billed annually<br/>
                      ${plan.monthlyPrice} billed monthly
                    </PriceDetails>
                  </div>
                  <ul>
                    {plan.features.map(feature => {
                      const text = feature.striked ? (
                        <NotAvailable>{feature.text} </NotAvailable>
                      ) : `${feature.text} `

                      return (
                        <li key={feature.text}>
                          {text}
                          {feature.hintText && (
                            <Tooltip title={feature.hintText}>
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                  <StyledButton disabled icon="download" 
                  // href={this.state.thisOSDownload} 
                  // onClick={() => {
                  //   mixpanel.track('Download', {position: `Pricing: ${plan.name}`})
                  //   ReactPixel.track('Lead', {plan: plan.name})
                  // }} 
                  type={plan.isPrimary ? 'primary' : 'default'}>Get started</StyledButton>
                  <FreeTrialBox>
                    <TrialText/>
                  </FreeTrialBox>
                </Plan>
              ))}
            </Plans>
          </Container>
        )}
      </Consumer>
    )
  }
}
