import { version } from '../../../electron/app/package.json'
const isWindows = typeof navigator !== 'undefined' && navigator.appVersion.indexOf("Windows") !== -1
const thisOSName = !isWindows ? 'macOS' : 'Windows'
const otherOSName = isWindows ? 'macOS' : 'Windows'
const iconName = !isWindows ? 'apple' : 'windows'
const macDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions-${version}.dmg`
const windowsDownload = `https://s3.us-east-2.amazonaws.com/fbq/Find+Better+Questions+Setup+${version}.exe`
const thisOSDownload = !isWindows ? macDownload : windowsDownload
const otherOSDownload = isWindows ? macDownload : windowsDownload

export {
  version,
  iconName,
  thisOSName,
  otherOSName,
  thisOSDownload,
  otherOSDownload
}
