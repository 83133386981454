import React from 'react'
import { SectionTitles, Container, TitleDescription } from './styles'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import Animation from './process/Animation'

const List = styled.ol`
  list-style: none;
  counter-reset: li;
  width: 85%;
  margin: 0 auto;

  li {
    position: relative;‚
  }

  li::before {
    content: counter(li);
    counter-increment: li;

    font-weight: 700;
    text-align: center;

    border: #222 solid 0.1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;

    position: absolute;
    top: -0.2rem;
    margin-left: -3em;

    @media (max-width: 32rem) {
      margin-left: -2.2em;
    }
  }
`

export default class Process extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPaused: true,
      1: false,
      2: false,
      isVisible: false,
    }
  }

  isReady = (number) => {
    this.setState({[number]: true})

    this.play()
  }

  onVisibilityChange = (isVisible) => {
    this.setState({isVisible})

    this.play()
  }

  play = () => {
    if (this.state[1] && this.state[2] && this.state.isVisible) {
      this.setState({isPaused: false})
    }
  }

  render() {
    return (
      <Container id="demo">
        <SectionTitles>
          <h4>How it works</h4>
          <h2>Level up your Quora Marketing</h2>
        </SectionTitles>
        <TitleDescription>Comparing the stats of hundreds of Quora questions is a pain.  Since no one can handle that many browser tabs, it’s best to create a spreadsheet. But that still takes hours. You could ask an assistant to do it for you, or let Find Better Questions do the magic, 100% automatically.</TitleDescription>
        <List id="process-list">
          <li>
            <h3>Create a List</h3>
            <p>Give the tool a reference point on Quora and it will create a list of questions based on the URL you fed it.</p>
            <VisibilitySensor onChange={this.onVisibilityChange} >
              <Animation number={1} isPaused={this.state.isPaused} isReady={this.isReady}/>
            </VisibilitySensor>
          </li>
          <li>
            <h3>Select the Best Questions</h3>
            <p>Once finished, you can view a table with the stats of each question and only answer the ones that will generate the most traffic.</p>
            <Animation number={2} isPaused={this.state.isPaused} isReady={this.isReady}/>
          </li>
        </List>
      </Container>
    )
  }
}
