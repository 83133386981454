import React from 'react'
import Helmet from 'react-helmet'
import Header from '../components/home/Header'
import Pain from '../components/home/Pain'
import Process from '../components/home/Process'
import StartTrial from '../components/home/StartTrial'
import Pricing from '../components/home/Pricing'
import FAQ from '../components/home/FAQ'
import Logo from '../components/home/Logo'
import styled from 'styled-components'
import Container from '../components/home/styles/Container'
import '../styles.less'
import { Divider } from 'antd';
import Layout from '../layouts/index'
import SignUp from '../components/book/SignUp'

const FAQBackground = styled.div`
  z-index: -3;

  position: absolute;
  left: 50%;
  width: 50%;
  margin-top: 20rem;

  overflow: hidden;
`

class Home extends React.Component {
  render() {
    return (
      <Layout>

        <Helmet title={'Find Better Questions on Quora'} />

        <Header/>

        <Container>
          <Divider/>
        </Container>

        <Pain/>

        {/* <div style={{width: '100%', textAlign: 'center', marginBottom: '2em'}}>
          <a style={{boxShadow: 'initial'}} href="https://www.producthunt.com/posts/find-better-questions?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-find-better-questions" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=155100&theme=light" alt="Find Better Questions - Answer Quora’s best questions & get traffic | Product Hunt Embed" style={{width: '250px', height: '54px', boxShadow: '0 0 0 0 white', margin: '0 auto'}} width="250px" height="54px" /></a>
        </div> */}

        <StartTrial position="top" text="Gain a bird’s-eye view of Quora today"/>

        <Process/>

        <SignUp additionalText="Want to read the Ultimate Guide to Quora Marketing? "/>


        <Pricing/>

        <StartTrial position="bottom" text="The first 7 days are on us!"/>

        <FAQBackground>
          <Logo size="70rem" color="#FFF8E8"/>
        </FAQBackground>
        <FAQ/>

        <div style={{ textAlign: 'center' }}>
          © 2019 Find Better Questions<br/>
          Quora is a registered trademark of Quora Inc.<br/>
          Find Better Questions is not affiliated with Quora.
        </div>
      </Layout>
    )
  }
}

export default Home
